import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import "../styles/global.scss"
// import MenuList from "../components/MenuList"

const IndexPage = () => (
  <Layout title="Testimonies">
    <SEO title="Testimonies" />
    <section>
      <div className="container">
        <div className="columns is-8 is-variable">
          {/* <div className="column is-one-quarter">
          <aside 
            className="menu"
            style={{position: `sticky`, top: `1em`}}>
            <MenuList>
              <li><a>My Most Reasonable Service</a></li>
              <li><a>More to come, soon!</a></li>
              <li><a>Change</a></li>
              <li><a>I Love the Lord</a></li>
              <li><a>The Truth Shall Set You Free</a></li>
              <li><a>Where Am I?</a></li>
            </MenuList>
            </aside>
          </div> */}
          <div className="column testimonies" style={{ 
            fontFamily: `Georgia, Times New Roman, serif`,
            fontSize: `1.3em`,
            lineHeight: `1.7em`}}>
            <h2 className="title">My Most Reasonable Service</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>D.L</h4>
            <p>I was born of Christian parents and very early in my life I was dedicated to the Lord for His purpose. When I was nine years old the brooding Spirit was seeking me out and I received the Lord Jesus as my Savior. That was 43 years ago!</p>
            <p>As the years progressed, so did the Lord in me. My heart always had a seeking for more of the Lord and as I grew older I began to search for more of the joy of my salvation, I wanted to know what it meant to be fully satisfied with Christ.</p>
            <p>At 19 years of age I had a wonderful realization that it was my most reasonable service to give myself, without reservation, to my wonderful Lord. This was a marvelous consecration and the Lord continued to lead me to Himself.</p>
            <p>In 1969, through the prayers of others and by the Lord’s mercy, I was brought to a meeting of the local church in Yorba Linda, California, where there was a level of enjoyment in the Lord that I had never tasted before in my Christian experience. From that memorable summer 30 years ago, I found a lasting and meaningful purpose for my life and my longing to know how fully satisfying Christ can be has been answered completely through my days here in the local church. I have never had one day of regret that I chose to give myself 100% to my Lord and King. I praise and worship Him for leading me to the local church in order that I might enjoy Him today.</p>

            <h2 style={{marginTop: `3em`}} className="title">A Short Testimony</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>J.F.</h4>
            <p>From a young age my goal in life was to become a medical missionary in Latin America. What Albert Schweitzer did in Africa, I wanted to do in Latin America. I had read his biography and was inspired by his life and work. I had also been influenced by my grandfather who was a pharmacist in my birth town in Mexico. Although he had died before I was born, his life story as told by my mother, and his medical books inspired me to become a physician.</p>
            <p>When I entered college my goal was still the same; however, I began to consider my own philosophy of life. I attempted to formulate principles by which I wanted to live, and to develop an overall view of God, man, society, and my place in this universe. To accomplish this I felt I needed to read as much as I could from the great thinkers of the past. By the time I got to my junior year, although I had gained a lot of knowledge, I was still uncertain about God, man and society. All I knew was that I had a definite goal to reach and that I wanted to help my fellow man. Inwardly though, my groping and groaning was becoming more intense as time passed. It seemed that the more I searched for truth, the emptier and more dissatisfied I became.</p>
            <p>My search ended when a Christian college student asked me if I knew the Lord Jesus. He had just finished singing with other Christians and when he turned to me, his face was shining. I had the distinct realization that he had something that I didn’t have—peace and joy. When I told him that I didn’t know the Lord, he led me to pray with him to accept the Lord Jesus as my Savior. This occurred in April 1974 on the UCLA campus.</p>
            <p>After my initial salvation experience, I wanted to meet with other Christians. I also wanted to know the Bible and know the Lord more. Three years later, during my first year in medical school at UC Irvine, I met Christians who met as the local church in Irvine. Their meetings were unlike any I had known. I saw people enjoying the Lord, singing these marvelous hymns and speaking things I had never heard. Attending these meetings made me feel washed and cleansed within. My initial consideration was that this was an excellent place to get Bible knowledge and some spiritual growth, and that after a few years I would continue on my path to be a medical missionary. My plans took a radical turn, however, after I attended a Bible training given in 1978 by Witness Lee which was later published as part of the Life-study of Ephesians. It seems that I was taken to a high mountain by the Lord during that training to see His heart’s desire. I had never heard that God had an eternal purpose, which was according to His desire. My eyes were opened, and I gave myself to Him for His heart’s desire. My own desire to become a medical missionary was too low, His desire and purpose too great. The answers to my deepest questions came to light, and I saw my place in the universe: as a member of His Body, being prepared to be part of His Bride to hasten His return.</p>
            <p>Now I meet with the local church in Cypress, having moved here in 1983 after completing my medical residency training. I am a family physician happily married with three children. I had the privilege last year to travel to Russia, not as an independent medical missionary, but as a serving member of His Body, attending to the health of those laboring in Moscow. I saw what the Lord is doing in Russia and realized that He is recovering the whole earth through the gospel of His Kingdom. I am deeply grateful to the Lord for bringing me to the local church. Praise be to Him for His abounding mercy and grace.</p>

            <h2 style={{marginTop: `3em`}} className="title">Change</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>B.Y.</h4>
            <p>I was born in 1976 in Seoul, South Korea. I came to the United States in 1979. Also in 1979 I received the Lord. I never knew the Lord until my uncle told me about Him. Since 1979 I have come in contact with other believers in Christ. In November 1998 I met some believers who meet with the local church in Cypress. 1998 was the best school year. These Christians would hang out at the student activities center and I would hang out with them. I’m glad to have met them. I thank the Lord for them.</p>

            <h2 style={{marginTop: `3em`}} className="title">I Love the Lord</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>David Kang</h4>
            <p>I am a believer in my forties who received the Lord 17 years ago. I have been in the local church for nine years. Although the time I have been in the local church is short, I would like to testify of one experience that I cherish.</p>
            <p>When I first started meeting with the local church there were some things that I was not used to. One of them was saying, “Lord I love You.” When the believers got together they would boldly declare that they loved the Lord and say this during prayers.</p>
            <p>In my marriage life it was not even easy for me to tell my wife that I loved her, and at times I was shy to do so. I thought that expressing my love by saying “ I love you” was something only young lovers did. At my age, how could I say that to the God I worshipped? However, on the other hand I envied the believers who had such a close relationship with the Lord. Seeing the condition of my heart, I did not have the boldness to say I love Him.</p>
            <p>While I was going through this dilemma, a brother in the Lord helped me to express my love for the Lord. He prayed “Lord, even though our love toward You is not sufficient, we confess we love You.” Then in my heart I said “Oh, that’s right. Lord, when I look at myself I cannot say I love You, but isn’t there a small part in my heart which loves You? Lord, even though my love is lacking, I truly love You, and I want my love toward You to grow and go deeper.”</p>
            <p>After that, I came to know that the Bible is about the universal romance between God and man (Song of Songs). Then my confession of love toward the Lord became sweeter.</p>
            <p>I also saw that in Revelation 2:4, the Lord told the church in Ephesus to recover their first love. The first thing the Lord requires of us is not to toil and serve, but to love Him above everything else with the best love.</p>
            <p>I thank the Lord for bringing me by His mercy and grace to the local church where there are many members who confess that they truly love the Lord</p>

            <h2 style={{marginTop: `3em`}} className="title">The Truth Shall Set You Free</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>Anonymous</h4>
            <p>I thought I was living a proper Christian life until one day during prayer I came across John 8:32 which says “And you shall know the truth and the truth shall set you free.” This verse shone light into my heart. I saw that Christ is the truth, and that although I used to think I was free because I was regenerated, I had to confess I did not have the true freedom.</p>
            <p>After that experience, the Lord brought my spouse and me to a local church. Through the abundance of the supply of life from the Word in the speaking of the believers in the church, I came to know the Lord as the truth.</p>
            <p>By the clear word of the truth in the Bible, I realized that my natural life is being consumed (2 Corinthians 4:16), I am being freed from the bondage of sin, God’s life in me is growing (1 Corinthians 3:7), and I am partaking of the divine nature (2 Peter 1:4). Praise the Lord!</p>

            <h2 style={{marginTop: `3em`}} className="title">Where Am I?</h2>
            <h4 style={{ 
              fontStyle: `italic`, 
              color: `rgb(136, 136, 136)`, 
              fontFamily: `Georgia, Times New Roman, serif`,
              marginBottom: `1.8em` }}>Anonymous</h4>
            <p>As a wife and a mother of two children, I was a workingwoman who tried her best to resemble Christ. Sometimes I would spend my lunch hour or break time to have fellowship with a sister in the Lord who also worked. We shared with each other our experience of the Lord and we enjoyed God’s abundant grace together. Each time I sensed the freshness of Christ within her and this was very attractive and precious to me. However, there was an impure, hardened concept and element resisting within me.</p>
            <p>In the meantime, I came to know more about the local church, and the fellowship of the believers impressed me. After this my husband and I began to meet with the local church and through the first conference we attended, my position as a believer was clearly shown to me.</p>
            <p>What is the reality of Christ? This was the question asked by Witness Lee. Then within me there were other questions such as: Christ, who is He? and Where am I? I began to see that even though I thought I loved Christ and was a fervent Christian, the realm of Christ I was in was the realm of imperfect knowledge. Then more light began to shine into my heart and as a little member of the universal Body of Christ the answer to my question of where I am was given to me through 1 Corinthians. Today I am not trying to live a life of imitating Christ by my own effort, but as a member of the body who is captivated by Christ and one with the body. I am encouraged by the enlightening of God’s Word to live by Christ within and express Him in a full way (Romans 12:5; 1 Corinthians 13:4; Ephesians 1:22-23). I just thank the Lord for His grace and praise the mysterious Christ. Amen!</p>
            <h2 style={{marginTop: `3em`}} className="title">
              <Link to="/dan-leslie-memorial">Dan Leslie Memorial Meeting</Link>
            </h2>
        </div>
      </div>
    </div>
  </section>
</Layout>
)

export default IndexPage